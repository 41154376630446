import React, { useEffect, useState } from 'react'
import placeholder from '../Assets/featured.webp'
export const FeaturedCard = ({article, scrollLinkedArticleIntoView, image}) => {

    const [imageState, setImg] = useState(null)
    const [imgLoaded, setImgLoaded] = useState(false)

    let imgSrc = image && `https:${image.fields.file.url}`;

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImgLoaded(true);
        };
        img.src = imgSrc;
        setImg(img)
    }, [imgSrc]);

    if(imgLoaded)
    return (
        <div className="featured-card" onClick={() => scrollLinkedArticleIntoView(article)}
            style={{backgroundImage: `url(${imgLoaded ? imgSrc : placeholder})`}}
        >
            <p>
                {article.fields.articleHeader}
            </p>
        </div>
    )
    else return false
}