import { useCallback, useEffect, useState } from 'react';
import './App.css';
import waiting from './Assets/waiting.png'

import axios from 'axios'
import { LandingContainer } from './Landing/LandingContainer';
import FoldArticleButton from './Articles/FoldArticleButton';
import { ArticleContainer } from './Articles/ArticleContainer';
import { Notification } from './Notifications/Notification';
import ImgLoader from './Helpers/ImgLoader';
import { FeaturedContainer } from './Featured/FeaturedContainer';


function App() {
  const [articleOpenID, setArticleOpenID] = useState(null)
  const [articles, setArticles] = useState(null)
  const [tempImage, setTempImage] = useState([])
  const [featured, setFeatured] = useState([])
  const [notification, setNotification] = useState(false)
  const [fade, setFade] = useState(false)
  const [count, setCount] = useState({total: 0, current: 10, first: true})

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setFade(true)
        setTimeout(() => {
          setNotification(false)
        }, 2000)
      }, 2000);
    }
  }, [notification])

  useEffect(() => {
    const currentUrl = window.location.href.includes('article=') && window.location.href.split('article=').pop()
    console.log('current url', currentUrl)
    const date = new Date().toISOString
      (!(process.env.NODE_ENV === 'development') && axios.post(`https://discord.com/api/webhooks/${process.env.REACT_APP_channel_id}/${process.env.REACT_APP_channel_key}`, { "content": `${new Date()}` }))
    axios.get(
      `https://cdn.contentful.com/spaces/${process.env.REACT_APP_space_id}/environments/master/entries?access_token=${process.env.REACT_APP_access_token}&metadata.tags.sys.id[all]=${process.env.REACT_APP_content_tag}&fields.publishedDate[lte]=${date}&order=-fields.publishedDate${currentUrl ? '' : '&limit=' + count.current}&content_type=article`)
      .then(x => {
        count.first && setCount({total: x.data.total, current: 10, first: false})
        const incomingArticles = x.data

        if (currentUrl) {
          incomingArticles.items.sort((a, b) => new Date(b.fields.publishedDate).getTime() - new Date(a.fields.publishedDate).getTime()).forEach((curr) => {
            if (curr.sys.id === currentUrl) {
              setArticleOpenID(curr.sys.id)
              scrollLinkedArticleIntoView(curr)
            }
          })
        }

        const featured = incomingArticles.items.reduce((curr, article, i) => {

          let featuredArticle = article.metadata.tags.some((x) => {
            return x.sys.id === "featured"
          })
          if (featuredArticle && i === 0) {
            return curr
          }
          if (curr.length < 3) {
            return featuredArticle ? [...curr, article] : curr
          }
          else return curr;
        }, [incomingArticles.items[0]])
        setFeatured(featured)
        setArticles(incomingArticles)
        setTempImage(incomingArticles.includes.Asset)
      })
  }, [count])

  const scrollLinkedArticleIntoView = (article) => {
    setTimeout(() => {
      document.querySelector('.regular-article' + (article?.sys?.id ?? article))
        .scrollIntoView({ behavior: 'instant', block: 'start' }
          , 100)
    })
  }

  const closeArticleAndSnapBackToTop = (article) => {
    const element = document.querySelector('.regular-article' + article);
    if (element.getBoundingClientRect().bottom > 0) {
      scrollLinkedArticleIntoView(article)
    }
    setArticleOpenID(null)
  }

  const debounce = (func, delay) => {
    let timeoutId;

    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }


  const checkOpenArticles = useCallback(() => {
    const debouncedCheckOpenArticles = debounce(() => {
      if (articleOpenID) {
        const element = document.querySelector('.regular-article' + articleOpenID);
        if (element && element.getBoundingClientRect().bottom < 0) {
          setArticleOpenID(null);
          setTimeout(() => {
            const next = element.nextElementSibling;
            if (next) {
              next.scrollIntoView({ behavior: 'instant', block: 'start' });
            }
          }, 50);
        }
      }
    }, 300);

    debouncedCheckOpenArticles();
  }, [articleOpenID]);

  const handlePaginationClick = () => {
    const currentArticleCount = count.current
    setCount({...count, current: currentArticleCount+3})

  }

  return (
    <div className="App" onScroll={checkOpenArticles}>
      <ImgLoader imageUrls={[waiting]} />
      {notification && <Notification fade={fade} />}
      <LandingContainer />

      <FeaturedContainer
        featuredArticles={featured}
        scrollLinkedArticleIntoView={scrollLinkedArticleIntoView}
        images={tempImage}
      />
      <main>
        <section>
          <FoldArticleButton articleOpenID={articleOpenID} closeArticleAndSnapBackToTop={closeArticleAndSnapBackToTop} />
          {articles && articles?.items?.sort((a, b) => new Date(b.fields.publishedDate).getTime() - new Date(a.fields.publishedDate).getTime()).map((article, i) => {
            return (
              <ArticleContainer
                setNotification={setNotification}
                key={article.sys.id}
                articleOpenID={articleOpenID}
                setArticleOpenID={setArticleOpenID}
                i={i}
                article={article}
                articles={articles}
                setFade={setFade}
                placeHolderImg={waiting}
              />
            )
          })}
          {(count.current < count.total) && <button onClick={handlePaginationClick}>{`Showing ${count.current} of ${count.total} - show more`}</button>}
        </section>
      </main >
    </div >
  );
}

export default App;
