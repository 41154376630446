import React from 'react'
import { FeaturedCard } from './FeaturedCard'

export const FeaturedContainer = ({ featuredArticles, scrollLinkedArticleIntoView, images }) => {
    return (
        <div id="featured-wrapper">
            {
                featuredArticles && featuredArticles?.map(x => {
                    return <FeaturedCard
                        image={images.find(image => {
                            return image?.sys?.id === x?.fields?.image?.sys?.id})}
                        article={x} key={x?.sys?.id}
                        scrollLinkedArticleIntoView={scrollLinkedArticleIntoView}
                    />
                })
            }
        </div>
    )
}